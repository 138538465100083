.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .logo {
    width: 100px;
    height: 100px;
    animation: scaleInOut 2s infinite ease-in-out;
  }
  
  @keyframes scaleInOut {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
  