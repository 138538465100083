@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
    .scrollbar-hide {
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
    }
  
    .scrollbar-hide::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }
  
    .scrollbar-thumb {
      background-color: #6b7280;  /* Customize this color */
      border-radius: 9999px;  /* Fully rounded corners */
    }
  
    .scrollbar-thumb:hover {
      background-color: #4b5563;  /* Customize hover color */
    }
  
    .scrollbar-track {
      background-color: #d1d5db;  /* Customize track color */
    }
  
    .scrollbar {
      scrollbar-color: #6b7280 #d1d5db;  /* Firefox */
      scrollbar-width: thin;  /* Firefox */
    }
  
    .scrollbar::-webkit-scrollbar {
      width: 8px;  /* Safari and Chrome */
    }
  
    .scrollbar::-webkit-scrollbar-thumb {
      background-color: #6b7280;  /* Customize this color */
      border-radius: 9999px;  /* Fully rounded corners */
    }
  
    .scrollbar::-webkit-scrollbar-thumb:hover {
      background-color: #4b5563;  /* Customize hover color */
    }
  
    .scrollbar::-webkit-scrollbar-track {
      background-color: #d1d5db;  /* Customize track color */
    }
  }